/**
 * Wait for refs in an interval and then once available scroll the active list item into view. This
 * function is used in the <Pills> and <Tabs> components to ensure that the active list item isn't
 * displayed off screen.
 * @see https://jefrydco.id/en/blog/safe-access-vue-refs-undefined
 */
import { nextTick } from 'vue';

export const scrollActiveItemIntoView = (
  self,
  refName,
  index,
  options = { block: 'end', inline: 'center' },
) => {
  const interval = window.setInterval(() => {
    if (self.$refs?.[refName]) {
      try {
        const activeItem = self.$refs[refName][index];

        if (activeItem.$el) {
          activeItem.$el.scrollIntoView(options);
        }
        else {
          activeItem.scrollIntoView(options);
        }
      }
      catch (e) {
        // Just do nothing if unable to scroll the active list item into view.
      }

      window.clearInterval(interval);
    }
  }, 50);
};

/**
 * Sometimes refs aren't immediately available. This function can be used to wait for them.
 * @see https://nuxtjs.org/docs/features/nuxt-components/#the-client-only-component
 */
export const waitForRef = async (component, ref) => new Promise((resolve, reject) => {
  const checkForRef = async (count = 10) => {
    await component.$nextTick();

    if (component?.$refs[ref]) {
      resolve();
    }
    else if (count > 0) {
      checkForRef(count - 1);
    }
    else {
      const mesg = `${ref || 'unknown'} ref not found in ${component?.$options?.name || 'unknown'} component`;
      reject(new Error(mesg));
    }
  };

  checkForRef();
});

export const waitForRef2 = async (ref) => new Promise((resolve, reject) => {
  const checkForRef = async (count = 10) => {
    await nextTick();

    if (ref.value) {
      resolve();
    }
    else if (count > 0) {
      checkForRef(count - 1);
    }
    else {
      const mesg = 'Ref not found!';
      reject(new Error(mesg));
    }
  };

  checkForRef();
});
